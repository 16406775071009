import "cross-fetch/polyfill";
//import * as Cognito from 'amazon-cognito-identity-js';
//import AmazonCognitoIdentity, {
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

//import Cognito from 'amazon-cognito-identity-js';

import config from "../config";

//console.log('---------- AmazonCognitoIdentity: ', AmazonCognitoIdentity);

//const Cognito = AmazonCognitoIdentity;

const poolData = {
  UserPoolId: config.userPoolId,
  ClientId: config.clientId,
};
//const userPool = new Cognito.CognitoUserPool(poolData);
//const userPool = new Cognito.CognitoUserPool(poolData);
//const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
const userPool = new CognitoUserPool(poolData);

const session = {
  user: null,
};

/*
const federatedAuthData = (() => {
  const authData = {
    AppWebDomain: config.hostedDomain,
    ClientId: config.clientId,
    RedirectUriSignIn: config.signInPath,
    RedirectUriSignOut: config.signOutPath,
    Storage: localStorage,
    TokenScopesArray: ['phone', 'email', 'openid','aws.cognito.signin.user.admin', 'profile'],
    UserPoolId: config.userPoolId
  };

  return {
    default: authData,
    facebook: {
      ...authData,
      IdentityProvider: 'Facebook'
    }
  };
})();*/

function builtCognitoUserAttribute(name, value) {
  var data = {
    Name: name,
    Value: value,
  };
  return new CognitoUserAttribute(data);
}

function getCurrentUser() {
  return session.user || userPool.getCurrentUser();
}

/*
function initFederatedAuth (authData) {
  var auth = new Cognito.CognitoAuth(authData);

  auth.useImplicitFlow();

  // Handles are overridden
  auth.userhandler = {
    onSuccess: () => {},
    onFailure: () => {}
  };

  return auth;
}

function signInWithFederatedAuth (authData) {
  federatedAuth = initFederatedAuth(authData);

  return new Promise(function (resolve, reject) {
    federatedAuth.userhandler = {
      onSuccess: function (_session) {
        resolve(_session);
      },
      onFailure: function (err) {
        reject(err);
      }
    };

    federatedAuth.getSession();
  });
}

function getLocalFederatedSession () {
  return federatedAuth.getSignInUserSession() ||
      federatedAuth.getCachedSession();
}

function isCurrentUserFederated () {
  var federatedSession = getLocalFederatedSession();
  if (federatedSession) {
    var payload = federatedSession.getIdToken().decodePayload();
    if (payload && payload.identities) {
      return payload.identities.some(function (identity) {
        return ['Facebook', 'Google', 'LoginWithAmazon'].indexOf(identity.providerName) !== -1;
      });
    }
  }
  return false;
}

function convertCognitoAuthSessionToCognitoUserSession (authSession) {
  return new Cognito.CognitoUserSession({
    AccessToken: new Cognito.CognitoAccessToken({
      AccessToken: authSession.getAccessToken().getJwtToken()
    }),
    IdToken: new Cognito.CognitoIdToken({
      IdToken: authSession.getIdToken().getJwtToken()
    }),
    RefreshToken: new Cognito.CognitoRefreshToken({
      RefreshToken: authSession.getRefreshToken().getToken()
    })
  });
}*/

//var federatedAuth = initFederatedAuth(federatedAuthData.default);

export const isAuthenticated = () => {
  session.user = getCurrentUser();
  if (session.user !== null) {
    return new Promise(function (resolve, reject) {
      /*
      if (isCurrentUserFederated()) {
        federatedAuth.userhandler = {
          onSuccess: function (federatedSession) {
            if (federatedSession.isValid()) {
              var signInUserSession = convertCognitoAuthSessionToCognitoUserSession(federatedSession);
              session.user.setSignInUserSession(signInUserSession);
              resolve(signInUserSession);
            } else {
              resolve(false);
            }
          },
          onFailure: function (err) {
            reject(err);
          }
        };
        federatedAuth.getSession();
      } else {*/
      session.user.getSession(function (err, _session) {
        if (err) {
          reject(err);
        } else if (_session.isValid()) {
          resolve(_session);
        } else {
          resolve(false);
        }
      });
      //}
    });
  } else {
    return Promise.resolve(false);
  }
};

export const signup = (user) => {
  return new Promise(function (resolve, reject) {
    var attributeList = [];

    attributeList.push(builtCognitoUserAttribute("email", user.email));
    attributeList.push(builtCognitoUserAttribute("given_name", user.firstName));
    attributeList.push(builtCognitoUserAttribute("family_name", user.lastName));

    if (user.birthdate) {
      attributeList.push(
        builtCognitoUserAttribute("birthdate", user.birthdate)
      );
    }

    userPool.signUp(
      user.email,
      user.password,
      attributeList,
      null,
      function (err, result) {
        if (err) {
          reject(err);
        } else {
          session.user = result.user;
          resolve(result);
        }
      }
    );
  });
};

export const signin = (user) => {
  return new Promise(function (resolve, reject) {
    var authenticationData = {
      Username: user.email,
      Password: user.password,
    };
    //var authenticationDetails = new Cognito.AuthenticationDetails(authenticationData);
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    var userData = {
      Username: user.email,
      Pool: userPool,
    };

    //session.user = new Cognito.CognitoUser(userData);
    session.user = new CognitoUser(userData);
    session.user.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        resolve(result);
      },

      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

//export const signInWithFacebook = () => {
//  return signInWithFederatedAuth(federatedAuthData.facebook);
//}

export const deleteUser = () => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      session.user.deleteUser(function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  } else {
    return Promise.resolve(false);
  }
};

export const getUserAttributes = () => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      session.user.getUserAttributes(function (err, result) {
        if (err) {
          reject(err);
        } else {
          var attibutes = result.reduce(function (attibutes, attibute) {
            attibutes[attibute.Name] = attibute.Value;
            return attibutes;
          }, {});
          resolve(attibutes);
        }
      });
    });
  } else {
    return Promise.resolve(false);
  }
};

export const updateUserAttibutes = (attibutes) => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      var attributeList = Object.keys(attibutes).map(function (name) {
        return builtCognitoUserAttribute(name, attibutes[name]);
      });

      session.user.updateAttributes(attributeList, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  } else {
    return Promise.resolve(false);
  }
};

export const getUserData = () => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      // If you want to force to get the user data from backend,
      // you can set the bypassCache to true
      session.user.getUserData(
        function (err, userData) {
          if (err) {
            reject(err);
          } else {
            resolve(userData);
          }
        },
        { bypassCache: true }
      );
    });
  } else {
    return Promise.resolve(false);
  }
};
/*
export const getUserIdentities = () => {
  var federatedSession = getLocalFederatedSession();

  if (federatedSession) {
    var payload = federatedSession.getIdToken().decodePayload();

    if (payload) {
      if (payload.identities) {
        return payload.identities.reduce(function (identities, identity) {
          identities[identity.providerName] = identity;
          return identities;
        }, {});
      }

      return {
        Cognito: {
          userId: payload['cognito:username']
        }
      };
    }
  }

  return {};
}*/

export const signOut = () => {
  session.user = getCurrentUser();

  //TODO: Fix federated sign out
  // if (isCurrentUserFederated()) {
  //   federatedAuth.signOut();
  // }
  // else
  if (session.user) {
    session.user.signOut();
  }
};

export const confirmUser = (code) => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      var username = session.user.getUsername();
      var userData = {
        Username: username,
        Pool: userPool,
      };

      session.user = new CognitoUser(userData);

      session.user.confirmRegistration(code, true, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  } else {
    return Promise.resolve(false);
  }
};

export const resendConfirmationCode = () => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      session.user.resendConfirmationCode(function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  } else {
    return Promise.resolve(false);
  }
};

export const forgotPassword = (userName) => {
  session.user = new CognitoUser({ Username: userName, Pool: userPool });

  return new Promise(function (resolve, reject) {
    session.user.forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

export const confirmPassword = (code, userName, password) => {
  session.user = new CognitoUser({ Username: userName, Pool: userPool });

  return new Promise(function (resolve, reject) {
    session.user.confirmPassword(code, password, {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

//export const parseCognitoWebResponse = (url) => {
//  federatedAuth.parseCognitoWebResponse(url);
//}

export const getUserDevices = (paginationToken) => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      session.user.listDevices(10, paginationToken, {
        onSuccess: function (result) {
          resolve(result.Devices);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  } else {
    return Promise.resolve([]);
  }
};

export const getUserCurrentDevice = () => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      session.user.getDevice({
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  } else {
    return Promise.resolve(null);
  }
};

export const forgetUserDevice = (deviceKey) => {
  session.user = getCurrentUser();
  if (session.user) {
    return new Promise(function (resolve, reject) {
      session.user.forgetSpecificDevice(deviceKey, {
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  } else {
    return Promise.resolve(null);
  }
};
