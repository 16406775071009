import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      //main: '#556cd6',
      main: "#3fb760",
    },
    secondary: {
      //main: '#19857b',
      main: "#eaeaea",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff", //252830', //rgb(37, 40, 48)' // '#fff',
    },
  },
  typography: {
    fontFamily: "var(--font-primary)",
  },
});

export default theme;
