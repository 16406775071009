import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../src/theme";
import * as CognitoService from "../../src/services/cognito.service";

import "../../src/main.css";

export default function TopLayout(props) {
  useEffect(() => {
    const allowedPaths = ["/", "/reset-password", "/signup", "/confirmation"];

    CognitoService.isAuthenticated()
      .then((response) => {
        if (!response && !allowedPaths.includes(window.location.pathname))
          window.location.href = "/";
        else sessionStorage.setItem("idToken", response.idToken.jwtToken);
      })
      .catch((error) => {});
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="shortcut icon" type="image/x-icon" href="favicon.ico" />

        <script
          src="https://www.googletagmanager.com/gtm.js?id=GTM-5NSVLM"
          async
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      if (!window.dataLayer[0] || !('gtm.start' in window.dataLayer[0]))
        window.dataLayer.unshift({'gtm.start': Date.now(), event: 'gtm.js'});
        `}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
