const config = {
  // Cognito test
  userPoolId: "us-east-1_jKaUyCPX4",
  clientId: "6se298oulbkspangddiv1o3mrf",
  hostedDomain: "mq-test.auth.us-east-1.amazoncognito.com",

  // Cognito prod
  //userPoolId: "us-east-1_97DMZ2NWE",
  //clientId: "58s6rbd61mkog80c08cev8vri7",
  //hostedDomain: "mapquest.auth.us-east-1.amazoncognito.com",

  // Position of identity UI
  position: "center",

  mymaps: {
    // Production
    //loginUrl: "https://mymaps.aws.mapquest.com/accounts/v1/login",
    //updateUrl: "https://mymaps.aws.mapquest.com/accounts/v1/update",
    //deleteUrl: "https://mymaps.aws.mapquest.com/accounts/v1/users",

    // Integration
    loginUrl: "https://mymaps-integration.aws.mapquest.com/accounts/v1/login",
    updateUrl: "https://mymaps-integration.aws.mapquest.com/accounts/v1/update",
    deleteUrl: "https://mymaps-integration.aws.mapquest.com/accounts/v1/users",
  },

  logo: "https://static.mqcdn.com/yogi-cdn/yogi/graphics/mq-full-logo-black.svg",
  includeBirthdate: true,
  verificationMethod: "code",

  yogi: {
    signInPath: "https://www.mapquest.com/",
  },

  phoenix: {
    signInPath: "https://hack22.aws.mapquest.com/",
  },
};

export default config;
